import React, {useContext, useEffect} from "react";
import ProductPage from "../Components/ProductPage";
import DescriptionBlockLeft from "../Components/DescriptionBlockLeft";
import DescriptionBlockRight from "../Components/DescriptionBlockRight";
import {ProductContext} from "../Utils/ProductContext";

function ProductMain() {
    // Extract product_handle from the URL path
    const productHandle = window.location.pathname.split("/")[2];
    const {products} = useContext(ProductContext); // Access products from ProductContext
    // Find the product by product_handle
    const product = products.find((p) => p.product_handle === productHandle);

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    // Show a loading or not found message if product is not yet loaded or doesn't exist
    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <ProductPage
            title={product.title}
            price={product.price}
            stock={product.stock}
            img={product.img}
            rating={product.rating}
            variants={product.variants}
            description={product.description.map((desc, index) => {
                // Dynamically render DescriptionBlockLeft or DescriptionBlockRight based on block type
                if (desc.type === "DescriptionBlockLeft") {
                    return (
                        <DescriptionBlockLeft
                            key={index}
                            title={desc.title}
                            description={<p>{desc.description}</p>}
                            img={desc.img}
                        />
                    );
                } else if (desc.type === "DescriptionBlockRight") {
                    return (
                        <DescriptionBlockRight
                            key={index}
                            title={desc.title}
                            description={<p>{desc.description}</p>}
                            img={desc.img}
                        />
                    );
                }
                return null;
            })}
            handle={productHandle}
        />
    );
}

export default ProductMain;
