import Star from "./Star";
import ReviewItem from "./ReviewItem";
import React, { useContext, useState } from "react";
import { ReviewContext } from "../Utils/ReviewContext";

function ReviewWidget({ product_handle }) {
    const reviewContext = useContext(ReviewContext);
    const reviews = reviewContext.getReviewsByProductHandle(product_handle);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const reviewsPerPage = 4;

    // Calculate the total number of pages
    const totalPages = reviews.length > 0 ? Math.ceil(reviews.length / reviewsPerPage) : 0;

    // Calculate the average rating with two decimal places
    const averageRating = reviews.length
        ? (reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length).toFixed(2)
        : "0.00";

    // Count ratings by each level (1 to 5 stars)
    const ratingCounts = [0, 0, 0, 0, 0];
    reviews.forEach(review => {
        if (review.rating >= 1 && review.rating <= 5) {
            ratingCounts[review.rating - 1]++;
        }
    });

    // Calculate percentage for each rating level
    const ratingPercentages = ratingCounts.map(count =>
        reviews.length ? ((count / reviews.length) * 100).toFixed(0) : "0"
    );

    // Determine the reviews to display on the current page
    const startIndex = (currentPage - 1) * reviewsPerPage;
    const currentReviews = reviews.slice(startIndex, startIndex + reviewsPerPage);

    // Handle pagination
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="flex flex-col items-center">
            <div className="w-full mx-16">
                <h1 className="text-[25px] text-white text-center mb-2 md:mb-0">Hodnocení zákazníků</h1>
                <div className="grid grid-cols-1 md:flex md:flex-col-2 w-full items-center">
                    <div className="w-full">
                        <div className="flex items-center justify-center mb-2">
                            {[...Array(5)].map((_, i) => (
                                <Star key={i} color={"text-blue-600"} size={4} />
                            ))}
                            <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">{averageRating}</p>
                            <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400"> z </p>
                            <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">5</p>
                        </div>
                        <p className="text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                            {reviews.length} celkových recenzí
                        </p>
                    </div>

                    {/* Rating bars */}
                    <div className="w-full">
                        {[5, 4, 3, 2, 1].map((star, index) => (
                            <div key={star} className="flex items-center justify-center mt-4 w-full">
                                <div className="inline-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <Star key={i} color={i < star ? "text-blue-600" : "text-black"} size={4} />
                                    ))}
                                </div>
                                <div className="w-1/2 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                    <div
                                        className="h-5 bg-blue-600 rounded"
                                        style={{ width: `${ratingPercentages[star - 1]}%` }}
                                    ></div>
                                </div>
                                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                    {ratingPercentages[star - 1]}%
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Individual reviews with pagination */}
            <div className="mt-6 w-full">
                <h3 className="text-lg font-semibold">Reviews</h3>
                {currentReviews.map((review, index) => (
                    <ReviewItem
                        key={index}
                        name={review.name}
                        date={review.date}
                        rating={review.rating}
                        avatarUrl={review.avatar_url}
                        title={review.title}
                        content={review.content}
                    />
                ))}
                {/* Pagination controls */}
                <div className="flex justify-center mt-4 items-center">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1 || totalPages === 0}
                        className="px-3 py-1 text-white text-lg bg-blue-500 rounded disabled:bg-gray-400"
                    >
                        &lt;
                    </button>
                    <span className="px-3 py-1 text-white text-sm">
                        {totalPages > 0 ? `${currentPage} / ${totalPages}` : "0 / 0"}
                    </span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages || totalPages === 0}
                        className="px-3 py-1 text-white text-lg bg-blue-500 rounded disabled:bg-gray-400"
                    >
                        &gt;
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ReviewWidget;
