import logo from "../imgs/logo.png";
import {useNavigate} from "react-router-dom";

function Footer() {
    const naigate = useNavigate();

    return (<div>


        <footer className="md:mt-0 mt-8 bg-transparent w-full">
            <hr className="border-gray-200 dark:border-[#262626]" />
            <div className="pt-8 pb-4 md:pt-12 md:pb-6 md:px-32 w-full  max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-[11px] text-gray-500 sm:text-center dark:text-gray-400">© 2024 <button onClick={() => naigate("/")}
                                                                                          className="hover:underline">AppleDrops™</button>. All Rights Reserved.
    </span>
            </div>
        </footer>

    </div>)
}

export default Footer;