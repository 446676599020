import React, { useContext, useEffect } from "react";
import Card from "../Components/Card";
import { useNavigate } from "react-router-dom";
import { CardContext } from "../Utils/CardContext";

function Ukoncene() {
    const { cards } = useContext(CardContext); // Access cards from CardContext
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div className={"flex flex-col mt-44 w-full text-center items-center justify-center font-normal"}>
            <div className={"px-8 md:px-0 w-full md:w-2/3 mb-4"}>
                <h1 className={"mb-4 text-[30px] md:text-[40px] font-bold text-white"}>Ukončené nabídky</h1>
                <p className={"md:mr-36 mr-0 md:ml-36 ml-0 leading-loose text-[15px] md:text-[16px] text-gray-300"}>
                    Zde najdete přehled<strong> již ukončených </strong>nabídek na naše Apple produkty. Každá nabídka
                    neboli "drop" má svůj odpočet kdy skončí. <strong>Nepropásněte proto svou šanci!</strong>
                </p>
                <button
                    onClick={() => navigate("/products/iphone")}
                    className={"mb-8 mt-4 px-9 py-3 bg-white text-blue-700 text-[15px] rounded-full transition"}
                >
                    Bežící Nabídky ZDE &larr;
                </button>
            </div>
            <div className={"mt-8 px-8 md:px-0 w-full md:w-2/3 "}>
                <p className={"mb-8 text-gray-300 text-[15px] md:text-[16px] text-left"}>
                    *Odpočet pro tyto produkty již skončil nebo nejsou skladem
                </p>
                <div className={"grid md:grid-cols-4 grid-cols-2 gap-4 mb-8"}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            product={card.product}
                            title={card.title}
                            img={card.img}
                            className={card.class_name || ""}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Ukoncene;
