import React, {useContext, useEffect, useState} from 'react';
import OrderTracking from "./OrderTracking";
import Star from "./Star";
import ReviewWidget from "./ReviewWidget";
import ReactCountryFlag from "react-country-flag"
import {ReviewContext} from "../Utils/ReviewContext";

function ProductPage({title, price, variants, rating, img, description, stock, handle}) {
    const reviewContext = useContext(ReviewContext);
    const reviews = reviewContext.getReviewsByProductHandle(handle);

    // Calculate the average rating with two decimal places
    const averageRating = reviews.length
        ? (reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length).toFixed(2)
        : "0.00";

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    // State to track the selected image and options
    const [selectedOptions, setSelectedOptions] = useState(
        variants.reduce((acc, variant) => {
            acc[variant.variantName] = variant.variantOptions[0]?.name || ''; // Default to first option if exists
            return acc;
        }, {})
    );

    // State for currently displayed image
    const [selectedImage, setSelectedImage] = useState(img);
    const [selectedPrice, setSelectedPrice] = useState(price);
    const [selectedPriceFromatted, setSelectedPriceFromatted] = useState(new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price));
    const [selectedStock, setStock] = useState(stock);
    const [selectedUrl, setUrl] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const handleIncrement = () => {
        setQuantity((prev) => {
            if (prev < 10) {
                return prev + 1;
            }
            return prev;
        });
    };

    const handleDecrement = () => {
        setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
    };

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (isNaN(value) || value <= 0) {
            setQuantity(1);
        } else {
            setQuantity(value);
        }
    };


    useEffect(() => {
        fetch('https://api.whustle.com/product-pay2/' + price + '/czk/AppleDrops', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setUrl(data.url);
            });
    }, [price]); // This will trigger only when price changes


    // Handler to update selected option and image
    const handleOptionSelect = (variantName, option) => {
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [variantName]: option.name,
        }));

        // Update the image if the selected option has one
        if (option.image) {
            setSelectedImage(option.image);
        }

        if (option.price && option.price !== selectedPrice) {
            //fetch POST https://api.whustle.com/product-pay2/PRICE/czk/AppleDrops
            fetch('https://api.whustle.com/product-pay2/' + option.price + '/czk/AppleDrops', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => response.json())
                .then(data => {
                    setUrl(data.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            setSelectedPrice(option.price);
            setSelectedPriceFromatted(new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(option.price));
        }

        if (option.stock) {
            setStock(option.stock);
        }
    };

    return (
        <div className="md:mt-0 mt-8 mx-8 md:mx-32">
            <div className="md:ml-24 pt-24 md:pt-32 grid grid-cols-1 md:grid-cols-2 gap-4 text-white">

                {/* Image Section */}
                <div>
                    <img src={selectedImage} alt={title}/>
                </div>

                {/* Product Information Section */}
                <div className={"md:ml-32 md:mt-12 flex flex-col"}>
                    <h1 className="text-[30px] md:text-[40px]">{title}</h1>

                    {rating ? <div className="inline-flex items-center -ml-1 space-x-2">
                        {[...Array(Math.round(averageRating))].map((_, index) => (
                            <Star
                                key={index}
                                size={4}
                                color={"text-blue-600"}
                            />
                        ))}
                        <p className=" mb-2 text-[13px] mt-3">{reviews.length} recenze</p>
                    </div> : <></>}

                    {stock > 0 && <div className="flex items-center tracking-wider space-x-2">
                        {/* Green circle */}
                        <div
                            className={(selectedStock >= 6 ? "bg-green-500" : "bg-orange-500") + " w-2 h-2 border border-white rounded-full"}>
                            <div
                                className={(selectedStock >= 6 ? "bg-green-500" : "bg-orange-500") + " w-2 h-2 rounded-full blur-sm"}>

                            </div>
                        </div>

                        {/* Text */}
                        <span className="text-gray-300 text-[10px] ">{selectedStock} SKLADEM</span>
                    </div>}

                    <div className="tracking-wider mt-2 mb-2 text-[16px] md:text-[18px] inline-flex items-center space-x-2">
                        <p>{selectedPriceFromatted} Kč</p>
                        {stock === 0 && <span
                            className="bg-gray-100 text-gray-800 text-[8px] font-medium px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                               Vyprodáno
                         </span>}
                    </div>
                    <p className="mb-1 text-gray-300 tracking-wider text-[10px]">Včetně daní</p>

                    {/* Variants */}
                    {stock > 0 && variants.map((variant) => (
                        <div key={variant.variantName}>
                            <p className="mt-2 text-gray-300 mb-1 text-[13px]">{variant.variantName}</p>
                            <div className="flex flex-wrap gap-2">
                                {variant.variantOptions.map((option) => (
                                    <button
                                        key={option.name}
                                        onClick={() => handleOptionSelect(variant.variantName, option)}
                                        className={`${
                                            selectedOptions[variant.variantName] === option.name
                                                ? "bg-white text-black"
                                                : "bg-transparent text-white"
                                        } border text-[14px] rounded-full py-2 px-5 m-1 cursor-pointer`}
                                    >
                                        {option.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ))}

                    {stock > 0 && <div>
                        <h1 className="mt-2 text-gray-300 text-[14px] md:text-[14px]"
                        >Množství</h1>
                        <div className="mt-2 flex items-center space-x-2 border border-white w-fit rounded-full">
                            {/* Minus button */}
                            <button
                                onClick={handleDecrement}
                                className="text-white rounded-full w-8 h-8 flex items-center justify-center "
                            >
                                -
                            </button>

                            {/* Input field */}
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleChange}
                                className="w-16 text-center text-gray-300 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            {/* Plus button */}
                            <button
                                onClick={handleIncrement}
                                className="text-white rounded-full w-8 h-8 flex items-center justify-center "
                            >
                                +
                            </button>
                        </div>
                    </div>}


                    {/* Add to Cart and Buy Now Buttons */}
                    {stock > 0 ? (<div className="grid grid-cols-1 w-full md:w-5/6 space-y-2 mt-4">
                        <button
                            className="mt-3 md:mr-0 mr-2 text-center px-5 md:px-20 py-3 md:py-3 font-semibold md:font-bold bg-blue-600 text-white text-[15px] md:text-[14px] rounded-full hover:bg-blue-700 transition"
                            onClick={(e) => {
                                e.preventDefault();
                                if (quantity > 1) {
                                    fetch('https://api.whustle.com/product-pay2/' + (selectedPrice * quantity) + '/czk/AppleDrops', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    }).then(response => response.json())
                                        .then(data => {
                                            window.location.href = data.url;
                                        })
                                        .catch((error) => {
                                            console.error('Error:', error);
                                        });
                                } else {
                                    window.location.href = selectedUrl;
                                }

                            }}
                        >
                            Koupit nyní
                        </button>
                    </div>) : (<div>
                        <button
                            className="mb-8 grid grid-cols-1 w-full md:w-5/6 space-y-2 mt-4 md:mr-0 mr-2 text-center px-5 md:px-20 py-3 md:py-3 font-semibold md:font-bold bg-gray-400
                             text-[15px] md:text-[16px] rounded-full transition disabled  cursor-not-allowed opacity-75">
                            Vyprodáno
                        </button>
                    </div>)}

                    {/* Free Shipping Info Box */}
                    {stock > 0 &&
                        <div className="w-full md:w-5/6 border border-white p-3 text-center space-y-1.5 mt-8 mb-2">
                            <h1 className="pt-0.5 font-bold text-[15px] md:text-[16px]">Doprava zdarma do <ReactCountryFlag countryCode="CZ" /> / <ReactCountryFlag countryCode="SK" /></h1>
                            <p className="pb-0.5 text-[12px]">*Doprava je vypočítána podle dodacích lhůt dodavatele*</p>
                        </div>}

                    {/* Order Tracking Component */}
                    {stock > 0 && <div className="md:ml-12 flex justify-center md:justify-start w-full md:w-1/3">
                        <OrderTracking/>
                    </div>}
                </div>
            </div>

            {/* Description Section */}
            <div className="mb-16">
                {description.map((desc, index) => (
                    <p key={index}>{desc}</p>
                ))}
            </div>

            {rating && <div className={"mb-8"}>
                <ReviewWidget product_handle={handle}/>
            </div>}
        </div>
    );
}

export default ProductPage;
