// CollapsibleItem.js
import React, {useState} from 'react';
const CollapsibleItem = ({title, icon, content}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className="border-t border-t-gray-900">
                <div className={"my-2"}>
                    {/* Header */}
                    <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={toggleCollapse}
                    >
                        <h3 className="text-[17px] font-normal items-center text-white inline-flex space-x-2">
                            <div className={"text-[20px]"}>{icon}</div>
                            <div>{title}</div>
                        </h3>
                    </div>

                    {/* Collapsible Content */}
                    {isOpen && (
                        <div className="leading-loose tracking-wider p-4 text-left text-[15px] md:text-[16px] font-normal">
                            <p className="text-gray-300">{content}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollapsibleItem;
