import React, {useEffect, useState} from "react";
import CollapsibleItem from "../Components/CollapsibleItem";
import {CiCircleInfo} from "react-icons/ci";
import {IoMdInformationCircleOutline, IoMdPricetag} from "react-icons/io";
import {IoPricetagOutline} from "react-icons/io5";
import {FiCheckSquare, FiPackage} from "react-icons/fi";

function Dotazy() {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    // State for form inputs
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    // Handle input changes
    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can add your form submission logic (e.g., send data to an API)
        alert("Thank you for your inquiry! We'll get back to you soon.");
        // Reset form after submission
        setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
    };

    return (
        <div className="flex flex-col mt-44 w-full text-center items-center justify-center font-bold">
            <h1 className="mb-5 text-white text-[30px] md:text-[40px]">Nejčastější dotazy</h1>
            <div className="px-8 md:px-0 w-full md:w-1/2 my-4">
                <CollapsibleItem
                    icon={<IoMdInformationCircleOutline/>}
                    title={"Proč je to tak levné?"}
                    content={
                        <div>
                            <p>Od roku 2021 se nám podařilo získat Apple produkty za extrémně <strong>nízké
                                ceny</strong>, což je výsledkem naší úzké <strong>spolupráce </strong>se
                                společností <strong>Apple</strong>. Díky výjimečným vztahům a přímé komunikaci máme
                                možnost pravidelně <strong>získávat testovací objednávky</strong> <strong>a speciální
                                    edice produktů</strong> za <strong>zvýhodněné ceny</strong>.
                            </p>
                            <br/>

                            <p>Apple
                                nás <strong>zahrnuje do svých pilotních projektů</strong> a<strong> testovacích
                                    programů</strong>, což nám umožňuje nabízet ty <strong>nejnovější </strong>modely
                                za <strong>bezkonkurenční ceny</strong>.
                            </p>
                            <br/>

                            <p>Tyto testovací produkty jsou <strong>zcela
                                nové a plně funkční</strong>, přičemž prošly přísnými <strong>kontrolami
                                kvality</strong>.
                                Naše dlouholetá spolupráce s Apple nám rovněž zaručuje přístup k exkluzivním nabídkám,
                                které
                                bychom jinak nemohli poskytovat.
                            </p>
                            <br/>

                            <p>Díky těmto výhodám máme jedinečnou pozici na trhu,
                                kterou rádi sdílíme s našimi zákazníky. Nakupujte u nás a využijte příležitosti pořídit
                                si
                                prémiové <strong>Apple produkty za výjimečné ceny!</strong>
                            </p>

                        </div>
                    }
                />
                <CollapsibleItem
                    icon={<IoPricetagOutline/>}
                    title={"Zabezpečená platba"}
                    content={
                        <p>S<strong> AppleDrops </strong>můžete mít klid na duši s vědomím, že <strong>každá
                            transakce</strong>, kterou na naší stránce provedete, <strong>je chráněna pokročilou
                            šifrovací technologií </strong>pomocí nejvíce zabezpečené platební brány na světě - Stripe
                            LLC. Tím je zajištěno, že vaše <strong>informace </strong>o platbě budou <strong>přenášeny
                                bezpečně</strong> a nebudou se k nim moci dostat žádné neoprávněné osoby.
                        </p>
                    }
                />
                <CollapsibleItem
                    icon={<FiPackage/>}
                    title={"Kdy dorazí moje objednávka?"}
                    content={
                        <div>
                            <br/>
                            <p>Průměrné dodání objednávky trvá <strong>3-5 dní</strong>.
                            </p>
                            <br/>

                            <p>Jakmile provedete platbu,
                                snažíme se co <strong>nejrychleji odbavit</strong> vaši objednávku a odeslat ji k
                                doručení.
                                Po odeslání objednávky <strong>obdržíte trasovací číslo</strong> na váš e-mail.
                            </p>
                        </div>
                    }
                    />
                <CollapsibleItem
                    icon={<FiCheckSquare/>}
                    title={"Spokojenost zákazníků"}
                    content={
                    <p>U každého z našich <strong>předešlých dropů </strong>si můžete <strong>přečíst ověřené recenze</strong> od našich zákazníku, kteří již u nás zakoupili. <strong>Zakládáme si na</strong> diskrétním jednání a <strong>vzájemné důvěře</strong>.
                    </p>
                    }
                />
            </div>

            {/* Contact Form */}
            <div className="md:mx-0 px-8 w-full md:w-5/6 mb-8">
                <form className="md:px-60 mx-auto space-y-4 font-normal" onSubmit={handleSubmit}>
                    <h1 className="mt-10 md:text-[40px] text-[30px] font-bold text-white">Máš dotaz?</h1>
                    <p className="text-white md:text-[16px] text-[15px] font-normal pb-4">
                        Neboj se a využij naši zákaznickou podporu. Jsme tu pro tebe 24 hodin denně a 7 dní v týdnu!
                    </p>

                    <div className={"flex flex-col-1 md:flex-col-2 space-x-2"}>
                        <input
                            type="text"
                            id="name"
                            placeholder="Jméno"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />

                        <input
                            type="email"
                            id="email"
                            placeholder="E-Mail"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />
                    </div>

                    <input
                        type="text"
                        id="phone"
                        placeholder="Telefonní číslo"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />

                    <textarea
                        id="message"
                        rows="4"
                        placeholder="Tvoje zpráva..."
                        value={formData.message}
                        onChange={handleInputChange}
                        className="block p-2.5 w-full text-sm text-white bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>

                    <div className="w-full flex justify-center  items-center">
                        <button
                            type="submit"
                            className="mt-2 w-full md:w-1/2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                        >
                            Odeslat
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Dotazy;
