// ProductContext.js
import React, {createContext, useState, useEffect} from "react";

// Create a context
export const ProductContext = createContext();

// Context provider component
export function ProductProvider({children}) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.appledrops.eu/products");
                const data = await response.json();

                // Ensure we replace the products state rather than appending to it.
                setProducts(data);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchData();
    }, []); // This effect runs only once when the component mounts, so data is only fetched once.


    const addProduct = async (newProduct) => {
        const response = await fetch("https://api.appledrops.eu/products", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(newProduct),
        });
        const addedProduct = await response.json();
        setProducts([...products, {...newProduct, id: addedProduct.productId}]);
    };

    const modifyProduct = async (id, updatedProduct) => {
        try {
            const response = await fetch(`https://api.appledrops.eu/products/${id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(updatedProduct),
            });

            if (!response.ok) {
                throw new Error("Failed to update product");
            }

            const updatedProductFromAPI = await response.json();

            // Update the context's state with the modified product immediately
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.id === id ? updatedProductFromAPI : product
                )
            );

        } catch (error) {
            console.error("Error updating product:", error);
        }
    };


    const deleteProduct = async (id) => {
        await fetch(`https://api.appledrops.eu/products/${id}`, {method: "DELETE"});
        setProducts(products.filter((product) => product.id !== id));
    };

    return (
        <ProductContext.Provider value={{products, addProduct, modifyProduct, deleteProduct}}>
            {children}
        </ProductContext.Provider>
    );
}
