import React from 'react';
import {useNavigate} from "react-router-dom";

const ImageWithTextGrid = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-1 tablet:grid-cols-3 gap-0 reverse md:grid-cols-2 text-white text-center space-x-4">

            {/* Image Section */}
            <div
                className="md:hidden image-with-text__media-item image-with-text__media-item--small image-with-text__media-item--top">
                <div
                    className="pb-6 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                    >
                    <img
                        src="//appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716"
                        alt="Apple Design Award"
                        srcSet="
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=165 165w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=360 360w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=535 535w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=750 750w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=1070 1070w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=1500 1500w
                        "
                        className="w-full"
                        sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                        fetchpriority="auto"
                    />
                </div>
            </div>

            {/* Text Section */}
            <div className="mt-5 image-with-text__text-item">
                <div
                    className="image-with-text__content image-with-text__content--top image-with-text__content--desktop-center image-with-text__content--mobile-center image-with-text__content--adapt content-container background-transparent">
                    <h2 className="image-with-text__heading inline-richtext h1 mb-6">
                        <strong className={"text-[30px] md:text-[40px]"}>Proč je to tak levné?</strong>
                    </h2>
                    <div className="image-with-text__text rte body">
                        <p className={"text-[15px] md:text-[16px] leading-relaxed text-gray-300"}>
                            Od roku 2021 se nám podařilo získat Apple produkty za extrémně <strong>nízké ceny</strong>,
                            což je výsledkem naší úzké <strong>spolupráce </strong>se společností <strong>Apple</strong>.
                            Díky výjimečným vztahům a přímé komunikaci máme možnost pravidelně{' '}
                            <strong>získávat testovací objednávky a speciální edice produktů </strong>za{' '}
                            <strong>zvýhodněné ceny</strong>.
                        </p>

                    </div>
                    <button onClick={() => navigate("/nejcastejsi-dotazy")}
                            className="mt-8 text-blue-500 bg-white hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-[16px] px-5 py-3 text-center me-2 mb-2 dark:bg-white dark:hover:bg-gray-200 dark:focus:ring-gray-200">
                        Nejčastější dotazy ZDE
                    </button>
                </div>
            </div>

            {/* Image Section */}
            <div
                className="md:block hidden image-with-text__media-item image-with-text__media-item--small image-with-text__media-item--top mb-20">
                <div
                    className="pl-4 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                    >
                    <img
                        src="//appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716"
                        alt="Apple Design Award"
                        srcSet="
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=165 165w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=360 360w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=535 535w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=750 750w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=1070 1070w,
                            //appledrops.store/cdn/shop/files/Apple-WWDC23-event-photos-Apple-Design-Award-winners_big.jpg.large.jpg?v=1731370716&width=1500 1500w
                        "
                        width="1500"
                        height="909"
                        sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                        fetchpriority="auto"
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageWithTextGrid;
