import React from 'react';

const OrderTracking = () => {
    return (
        <div className="flex justify-center items-center space-x-4 bg-black py-6 w-64">
            {/* Step 1: Order */}
            <div className="text-center text-white">
                <div className="bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 7V5a2 2 0 012-2h2a2 2 0 012 2v2m10 0V5a2 2 0 00-2-2h-2a2 2 0 00-2 2v2M5 11h14a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2z"
                        />
                    </svg>
                </div>
                <p className="text-[15px] font-semibold">Objednávka přijata</p>
                <p className="text-gray-400">14/11</p>
            </div>

            {/* Step Divider Line */}
            <div className="flex-grow h-0.5 bg-blue-600"></div>

            {/* Step 2: On the Way */}
            <div className="text-center text-white">
                <div className="bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 9h2l.75 1.5M7 10.5h11a2 2 0 012 2v1a1 1 0 01-1 1H4m-1-4.5V14m1 4.5h3a4.5 4.5 0 009 0h3m-15 0v-2a2 2 0 012-2h12a2 2 0 012 2v2"
                        />
                    </svg>
                </div>
                <p className="text-[15px] font-semibold">Objednávka je na cestě</p>
                <p className="text-gray-400">15/11 - 17/11</p>
            </div>

            {/* Step Divider Line */}
            <div className="flex-grow h-0.5 bg-blue-600"></div>

            {/* Step 3: Delivery */}
            <div className="text-center text-white">
                <div className="bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 8c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0-7C6.477 1 2 5.477 2 11s4.477 10 10 10 10-4.477 10-10S17.523 1 12 1z"
                        />
                    </svg>
                </div>
                <p className="text-[15px]  font-semibold">Doručení</p>
                <p className="text-gray-400">19/11 - 21/11</p>
            </div>
        </div>
    );
};

export default OrderTracking;
