import logo from "../imgs/logo.png";
import {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {FiShoppingBag} from "react-icons/fi";
import {IoSearchOutline} from "react-icons/io5";
import {AiFillInfoCircle} from "react-icons/ai";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [logoSize, setLogoSize] = useState('h-[85px]'); // Default size
    const [menuSize, setMenuSize] = useState('h-[130px]'); // Default size
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation to get current path

    const currentPath = location.pathname; // Track the current path

    // Effect to handle scroll event
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                // If scrolled down 50px, reduce logo size
                setLogoSize('h-[65px]');
                setMenuSize('h-[110px]');
            } else {
                // If at the top, reset to original size
                setLogoSize('h-[85px]');
                setMenuSize('h-[130px]');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav
            className="px-4 bg-white dark:bg-black fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-[#262626]">
            <div
                className={`transition-all duration-300 flex items-center justify-between p-2 ${menuSize} relative mx-auto md:mx-[10.5%]`}>
                {/* Left Section: Hamburger Menu (Only on mobile) */}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="bg-transparent md:hidden inline-flex items-center p-2 w-8 h-8 justify-center text-xs rounded-lg text-white"
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>

                {/* Middle Section: Logo (Always visible, centered) */}
                <div
                    className="flex items-center justify-center flex-1 md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                    <button onClick={() => navigate("/")} className="flex w-full justify-center">
                        <img src={logo} className={`${logoSize} transition-all duration-300 p-1`} alt="Logo"/>
                    </button>
                </div>

                {/* Right Section: Search and Cart Icons (Only on mobile and desktop) */}
                <div className="flex items-center p-2 w-8 h-8 space-x-4 md:hidden">
                    <AiFillInfoCircle onClick={() => navigate("/nejcastejsi-dotazy")}
                                      className="text-gray-900 dark:text-white"/>
                </div>

                {/* Links and Icons (Visible only on desktop) */}
                <div className="hidden md:flex items-center justify-between w-full">
                    {/* Left Section: Links */}
                    <div className="flex space-x-4">
                        <button onClick={() => navigate("/")}
                                className={(currentPath === "/" ? "underline  text-white " : " text-[#A4A4A4] ") + " text-[14px] hover:text-white"}>Domů
                        </button>
                        <button onClick={() => navigate("/nejcastejsi-dotazy")}
                                className={(currentPath === "/nejcastejsi-dotazy" ? "underline text-white " : " text-[#A4A4A4] ") + " text-[14px] hover:text-white"}>Nejčastější
                            dotazy
                        </button>
                        <button onClick={() => navigate("/ukoncene-nabidky")}
                                className={(currentPath === "/ukoncene-nabidky" ? "underline  text-white " : " text-[#A4A4A4] ") + "  text-[14px] hover:text-white"}>Ukončené
                            nabídky
                        </button>
                    </div>

                    {/* Right Section: Search and Cart Icons */}
                    <div className="flex space-x-4">
                        <AiFillInfoCircle onClick={() => navigate("/nejcastejsi-dotazy")}
                                          className="h-6 w-6 text-gray-900 dark:text-white"/>
                    </div>
                </div>
            </div>

            {/* Mobile Menu (visible only when hamburger is open) */}
            <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
                <ul className="flex flex-col p-2 font-medium rounded-lg dark:border-gray-700">
                    <li>
                        <button onClick={() => {
                            navigate("/")
                            setIsOpen(false)
                        }}
                                className={(currentPath === "/" ? "underline " : "") + "block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent"}>Domů
                        </button>
                    </li>
                    <li>
                        <button onClick={() => {
                            navigate("/nejcastejsi-dotazy")
                            setIsOpen(false)
                        }}
                                className={(currentPath === "/nejcastejsi-dotazy" ? "underline " : "") + " block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent"}>Nejčastější
                            otázky
                        </button>
                    </li>
                    <li>
                        <button onClick={() => {
                            navigate("/ukoncene-nabidky")
                            setIsOpen(false)
                        }}
                                className={(currentPath === "/ukoncene-nabidky" ? "underline " : "") + "block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent"}>Ukončené
                            nabídky
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
