import {useNavigate} from "react-router-dom";

function Card({title, product, img, className}) {

    const navigate = useNavigate();

    return (<div className={"w-full " + className}>
        <div
            className=" max-w-sm        rounded-lg shadow   ">
            <button onClick={() => navigate("/products/" + product)}>
                <img className="w-150 h-150 rounded-t-lg" src={img} alt="product image"/>
            </button>
            <div className="px-5 pb-5">
                <div className="flex items-center justify-center">
                    <a href="#"
                       className="mt-3 text-gray-400 text-center font-bold">{title}</a>
                </div>
            </div>
        </div>

    </div>)
}

export default Card;