import Star from "./Star";

function ReviewItem({name, title, content, date, avatarUrl, img, rating}) {
    // Format the date
    const formattedDate = new Date(date).toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yy format

    return (
        <div className="flex flex-col items-start mt-6 w-full">
            <div className="flex items-center justify-between w-full">
                {/* Stars */}
                <div className="flex items-center">
                    {[...Array(rating)].map((_, i) => (
                        <Star color={"text-blue-600"} size={4}/>
                    ))}
                </div>

                {/* Date aligned to the far right */}
                <span className="text-sm text-gray-500 dark:text-gray-400">{formattedDate}</span>
            </div>

            {/* Name and Avatar */}
            <div className="flex mt-2">
                <img
                    src={avatarUrl || "https://placehold.co/40"}
                    alt={`${name}'s avatar`}
                    className="w-10 h-10 mr-4"
                />
                <span className="font-semibold text-white">{name}</span>
            </div>

            {/* Title and Content */}
            <p className="font-semibold mt-2 text-white">{title}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{content}</p>
        </div>
    );
}

export default ReviewItem;