// CardContext.js
import React, { createContext, useState, useEffect } from "react";

// Create a context
export const CardContext = createContext();

// Context provider component
export function CardProvider({ children }) {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        // Fetch cards data only once when the provider mounts
        fetch("https://api.appledrops.eu/cards")
            .then((response) => response.json())
            .then((data) => setCards(data))
            .catch((error) => console.error("Error fetching cards:", error));
    }, []);

    const addCard = async (newCard) => {
        const response = await fetch("https://api.appledrops.eu/cards", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newCard),
        });
        const addedCard = await response.json();
        setCards([...cards, { ...newCard, id: addedCard.cardId }]);
    };

    const modifyCard = async (id, updatedCard) => {
        await fetch(`https://api.appledrops.eu/cards/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedCard),
        });
        setCards(cards.map((card) => (card.id === id ? { ...updatedCard, id } : card)));
    };

    const deleteCard = async (id) => {
        await fetch(`https://api.appledrops.eu/cards/${id}`, { method: "DELETE" });
        setCards(cards.filter((card) => card.id !== id));
    };

    return (
        <CardContext.Provider value={{ cards, addCard, modifyCard, deleteCard }}>
            {children}
        </CardContext.Provider>
    );
}
