import './App.css';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Main from "./Pages/Main";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ProductMain from "./Pages/ProductMain";
import Dotazy from "./Pages/Dotazy";
import Ukoncene from "./Pages/Ukoncene";
import {CardProvider} from "./Utils/CardContext"
import {ProductProvider} from "./Utils/ProductContext";
import {ReviewProvider} from "./Utils/ReviewContext";
import Upload from "./Pages/Upload";

function App() {

    return (
        <BrowserRouter>
            <div className={"dark:bg-black flex flex-col min-h-screen"}>
                <CardProvider>
                    <ReviewProvider>
                        <ProductProvider>
                            <Navbar/>
                            <div className={"w-full"}>
                                <Routes>
                                    <Route path="/" element={<Main/>}/>
                                    <Route path="/nejcastejsi-dotazy" element={<Dotazy/>}/>
                                    <Route path="/ukoncene-nabidky" element={<Ukoncene/>}/>
                                    <Route path="/upload" element={<Upload/>}/>
                                    <Route path="/products/:id" element={<ProductMain/>}/>
                                </Routes>
                            </div>
                            <Footer/>
                        </ProductProvider>
                    </ReviewProvider>
                </CardProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
